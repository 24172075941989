import { InquiryStatusEnum } from 'src/helpers/Enums/Inquiry/InquiryStatusEnum';
import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { InquiryResponseDto } from 'src/types/api/landingPage/inquiry';

class Inquiries extends Client {
  api = {
    FETCH_INQUIRIES: `${this.http.baseUrl}/inquiries/filter`,
    FETCH_INQUIRY: `${this.http.baseUrl}/inquiries/inquiry/{inquiryId}`,
    CHANGE_MANAGER: `${this.http.baseUrl}/inquiries/inquiry/{inquiryId}/sales-manager`,
    CHANGE_STATUS: `${this.http.baseUrl}/inquiries/inquiry/{inquiryId}/change-status`,
  };

  fetchFilteredInquiries = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InquiryResponseDto>> => {
    return this.http.get(this.api.FETCH_INQUIRIES, { params: request });
  };

  fetchInquiry = (inquiryId: string): Promise<InquiryResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_INQUIRY, { inquiryId });
    return this.http.get(url);
  };

  changeManager = (inquiryId: string, req: { manager_id: string }): Promise<InquiryResponseDto> => {
    const url = this.buildUrl(this.api.CHANGE_MANAGER, { inquiryId });
    return this.http.put(url, req);
  };

  changeStatus = (
    inquiryId: string,
    req: { status: InquiryStatusEnum },
  ): Promise<InquiryResponseDto> => {
    const url = this.buildUrl(this.api.CHANGE_STATUS, { inquiryId });
    return this.http.put(url, req);
  };
}

export default Inquiries;
