import React, { useEffect, useState, useCallback } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Api from 'src/api';
import { RouteList } from 'src/routes';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import { InquiryResponseDto } from 'src/types/api/landingPage/inquiry';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import AssignManagerModal from 'src/components/Modal/Modals/AssignManagerModal';
import Loader from 'src/components/Loader';
import { InquiryStatusEnum } from 'src/helpers/Enums/Inquiry/InquiryStatusEnum';
import ChangeInquiryStatusModal from './ChangeInquiryStatusModal';
import CountryLabel from 'src/components/Labels/CountryLabel';

export const InquiryView = () => {
  const { t } = useTranslation();
  const { inquiryId } = useParams<{ inquiryId: string }>();
  const [inquiryResponse, setInquiryResponse] = useState<InquiryResponseDto>();
  const [loading, setLoading] = useState(true);
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    setLoading(true);
    Api.inquiries.fetchInquiry(inquiryId).then((response) => {
      setInquiryResponse(response);
      setLoading(false);
    });
  }, [inquiryId]);

  const changeManager = useCallback(() => {
    showModal(
      <AssignManagerModal
        onAction={async (request) => {
          return Api.inquiries.changeManager(inquiryId, request).then((res) => {
            setInquiryResponse(res);
          });
        }}
        title={t('applications.manager.assign.title')}
      />,
    );
  }, [inquiryId, showModal, t]);

  const changeStatus = useCallback(() => {
    if (inquiryResponse?.inquiry_status)
      showModal(
        <ChangeInquiryStatusModal
          currentStatus={inquiryResponse.inquiry_status}
          onAction={async (request) => {
            return Api.inquiries.changeStatus(inquiryId, request).then((res) => {
              setInquiryResponse(res);
            });
          }}
          title={t('applications.manager.assign.title')}
        />,
      );
  }, [inquiryId, inquiryResponse?.inquiry_status, showModal, t]);

  const statusTextColor =
    inquiryResponse?.inquiry_status === InquiryStatusEnum.REJECTED
      ? 'text-danger'
      : inquiryResponse?.inquiry_status === InquiryStatusEnum.CONVERTED
      ? 'text-success'
      : 'text-primary';

  return (
    <Container>
      <Row>
        <Col md={1} className={'mb-4'}>
          <Link to={RouteList.LANDING_PAGE.INQUIRIES.LIST}>
            <PrimaryButton title={t('common.back')} type={'button'} />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={'mb-4'}>
          <Card>
            {loading ? (
              <CardBody>
                <div className="d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              </CardBody>
            ) : (
              <CardBody>
                <Row>
                  <SidebarDataRow
                    label={t('table.manager_name')}
                    value={inquiryResponse?.manager}
                  />
                  <SidebarDataRow
                    label={t('table.status')}
                    value={
                      <span className={statusTextColor}>
                        {inquiryResponse?.inquiry_status.toUpperCase()}
                      </span>
                    }
                  />
                  <Col xs={6} cla>
                    <div className="d-flex justify-content-end gap-1">
                      <div className="d-flex gap-1">
                        <button
                          className={`btn  ${
                            inquiryResponse?.manager === null ? 'btn-primary' : 'btn-secondary'
                          }`}
                          onClick={changeManager}
                        >
                          {t('applications.manager.assign.title')}
                        </button>
                        <button
                          className={`btn btn-primary`}
                          onClick={changeStatus}
                          disabled={inquiryResponse?.manager === null}
                        >
                          {t('table.change_status')}
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <SidebarDataRow
                    label={t('table.created_at')}
                    value={t('common.datetime', { date: inquiryResponse?.created_at })}
                  />
                  <SidebarDataRow
                    label={t('table.updated_at')}
                    value={
                      inquiryResponse?.created_at !== inquiryResponse?.updated_at
                        ? t('common.datetime', {
                            date: inquiryResponse?.updated_at,
                          })
                        : null
                    }
                  />
                </Row>
                <hr />
                <h4 className={'mb-3'}>{t('pages.update_inquiry')}</h4>
                <Row>
                  <SidebarDataRow
                    label={t('label.name')}
                    value={inquiryResponse?.name}
                    colProps={{ md: 4, xs: 6 }}
                  />
                  <SidebarDataRow
                    label={t('label.email')}
                    value={inquiryResponse?.email}
                    colProps={{ md: 4, xs: 6 }}
                  />
                  <SidebarDataRow
                    label={t('label.phone_number')}
                    value={inquiryResponse?.phone_number}
                    colProps={{ md: 4, xs: 6 }}
                  />
                </Row>
                <br />
                <Row>
                  <SidebarDataRow
                    label={t('label.company_name')}
                    value={inquiryResponse?.company_name}
                    colProps={{ md: 4, xs: 6 }}
                  />
                  <SidebarDataRow
                    label={t('label.legal_code')}
                    value={inquiryResponse?.legal_code}
                    colProps={{ md: 4, xs: 6 }}
                  />
                  <SidebarDataRow
                    label={t('label.country')}
                    value={
                      inquiryResponse?.country && <CountryLabel code={inquiryResponse.country} />
                    }
                    colProps={{ md: 4, xs: 6 }}
                  />
                </Row>
                <Row>
                  <SidebarDataRow
                    label={t('label.credit_purpose')}
                    value={t('applications.type.' + inquiryResponse?.credit_purpose)}
                    colProps={{ md: 4, xs: 6 }}
                  />
                  <SidebarDataRow
                    label={t('label.required_amount')}
                    value={inquiryResponse?.required_amount}
                    colProps={{ md: 4, xs: 6 }}
                  />
                  <SidebarDataRow
                    label={t('label.real_estate_value')}
                    value={inquiryResponse?.real_estate_value}
                    colProps={{ md: 4, xs: 6 }}
                  />
                </Row>
                <Row>
                  <SidebarDataRow
                    label={t('label.description')}
                    value={inquiryResponse?.description}
                    colProps={{ xs: 12 }}
                  />
                </Row>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
