import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import Api from 'src/api';
import { Permissions } from 'src/helpers/auth/permissions';
import usePermissions from 'src/helpers/usePermissions';
import { mapManagePermission, ReportTypeEnum } from 'src/helpers/Enums/ReportTypeEnum';
import { success } from 'src/services/toastr';
import TextInput from 'src/components/Form/TextInput';
import SaveButton from 'src/components/Form/SaveButton';
import {
  GenerateReportRequestDto,
  RoboInvestorStrategiesReportFilterForm,
} from 'src/types/api/reports';
import { appendDateFilter, appendFilter, initialRequest } from 'src/pages/Reports/View/helpers';
import SelectColumnsInput from 'src/pages/Reports/View/Filters/Selects/SelectColumnsInput';
import useReportTypeStore from 'src/pages/Reports/View/ReportTypeStore';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { SelectInput, SelectAsyncInput } from 'src/components/Form/Select';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { AutoInvestorStatusSelectorEnum } from 'src/helpers/Enums/AutoInvestor/AutoInvestorStatusSelectorEnum';
import { ResidenceSelectorEnum } from 'src/helpers/Enums/Payments/ResidenceSelectorEnum';
import { PaginationDataFilter } from 'src/types';

const AVAILABLE_COLUMNS: string[] = [
  'name',
  'investors_pid',
  'investors_name',
  'investors_email',
  'investors_phone',
  'investors_residence',
  'investors_gender',
  'status',
  'created_at',
  'activated_at',
  'updated_at',
  'last_investment_date',
  'deleted_at',
  'strategy_order',
  'total_investments_count',
  'total_investments_amount',
  'strategy_amount',
  'can_invest_less',
  'interest_from',
  'interest_to',
  'credit_duration_from',
  'credit_duration_to',
  'max_share_long_term',
  'max_share_per_borrower',
  'risk_category_to',
  'country',
  'rating_profitus',
  'investment_purpose',
  'security_measures',
];

const TYPE: ReportTypeEnum = ReportTypeEnum.ROBO_INVESTOR_REPORT;

const RoboInvestorStrategiesReportFilter: React.FC = () => {
  const { t } = useTranslation();
  const p = usePermissions();
  const { refreshTable } = useReportTypeStore();

  const [request] = useState<RoboInvestorStrategiesReportFilterForm>({
    name: null,
    columns: AVAILABLE_COLUMNS,
    user_pid: null,
    residence: null,
    status: null,
    created_at_from: null,
    created_at_to: null,
    activated_at_from: null,
    activated_at_to: null,
  });

  const [residenceOptions] = useState<ReactSelectOption[]>([
    ...Object.values(ResidenceSelectorEnum).map((type) => ({
      value: type,
      label: t('users.residence.' + type),
    })),
  ]);

  const [statusOptions] = useState<ReactSelectOption[]>([
    ...Object.values(AutoInvestorStatusSelectorEnum).map((type) => ({
      value: type,
      label: t('reports.robo_investor_strategy_status.' + type),
    })),
  ]);

  const mapData = (form: RoboInvestorStrategiesReportFilterForm): GenerateReportRequestDto => {
    const request = initialRequest(form, TYPE);

    appendFilter(request, 'user_pid', form.user_pid);
    appendFilter(request, 'residence', form.residence);
    appendFilter(request, 'status', form.status);
    appendDateFilter(request, 'created', form.created_at_from, form.created_at_to);
    appendDateFilter(request, 'activated', form.activated_at_from, form.activated_at_to);

    return request;
  };

  const onSubmit = useCallback(
    async (
      request: RoboInvestorStrategiesReportFilterForm,
      helper: FormikHelpers<RoboInvestorStrategiesReportFilterForm>,
    ) => {
      try {
        await Api.reports.generateReport(mapData(request)).then(() => {
          refreshTable();
          success(t('common.success'));
        });
      } catch (e: any) {
        helper.setErrors(e.response?.errors);
      }
    },
    [refreshTable, t],
  );

  const fetchUsers = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.user.fetchFilteredInvestorsUsers(request);

    return response.data.map((item) => ({
      value: item.private_id ?? '-',
      label: item.private_id ?? '-',
    }));
  };

  if (!p.hasAll([mapManagePermission(TYPE), Permissions.BO__REPORTS__GENERATE])) return null;

  return (
    <div className={'mb-4'}>
      <Card>
        <CardBody>
          <h4 className={'mb-4'}>{t('reports.filter.' + TYPE)}</h4>
          <div className={'mb-4'}>
            <Formik initialValues={request} onSubmit={onSubmit} enableReinitialize={true}>
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Row>
                      <Col sm={2} className={'mb-4'}>
                        <TextInput name={'name'} />
                      </Col>
                      <Col sm={1} className={'mb-4'}>
                        <SelectColumnsInput columns={AVAILABLE_COLUMNS} />
                      </Col>
                      <Col sm={3} className={'mb-4'}>
                        <SelectAsyncInput
                          isMulti={true}
                          name={'user_pid'}
                          isClearable={true}
                          loadOptions={fetchUsers}
                        />
                      </Col>
                      <Col sm={3} className={'mb-4'}>
                        <DatePickerInput name={'created_at_from'} showTimeSelect={true} />
                      </Col>
                      <Col sm={3} className={'mb-4'}>
                        <DatePickerInput name={'created_at_to'} showTimeSelect={true} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className={'mb-4'}></Col>
                      <Col sm={3} className={'mb-4'}>
                        <SelectInput
                          placeholder={t('label.investor_country')}
                          name={'residence'}
                          options={residenceOptions}
                          isClearable={true}
                        />
                      </Col>
                      <Col sm={2} className={'mb-4'}>
                        <SelectInput
                          placeholder={t('label.status')}
                          name={'status'}
                          options={statusOptions}
                          isClearable={true}
                        />
                      </Col>
                      <Col sm={3} className={'mb-4'}>
                        <DatePickerInput name={'activated_at_from'} showTimeSelect={true} />
                      </Col>
                      <Col sm={3} className={'mb-4'}>
                        <DatePickerInput name={'activated_at_to'} showTimeSelect={true} />
                      </Col>
                    </Row>
                    <div className={'mb-4 mt-3'}>
                      <SaveButton title={t('common.generate')} submitting={isSubmitting} />
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default RoboInvestorStrategiesReportFilter;
