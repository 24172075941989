import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import SaveButton from 'src/components/Form/SaveButton';
import SelectInput from 'src/components/Form/Select/SelectInput';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import { InquiryStatusEnum } from 'src/helpers/Enums/Inquiry/InquiryStatusEnum';

interface ChangeInquiryStatusModalProps {
  onAction: (request: any) => Promise<any>;
  currentStatus: InquiryStatusEnum;
  title?: string;
  body?: string;
}

interface ChangeStatusDto {
  status: string;
}

const ChangeInquiryStatusModal: React.FC<ChangeInquiryStatusModalProps> = ({
  onAction,
  currentStatus,
  title,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [changeProspectApplicationStatusRequest] = useState<ChangeStatusDto>({
    status: currentStatus,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = useCallback(
    async (request: ChangeStatusDto, helper: FormikHelpers<ChangeStatusDto>) => {
      return onAction(request)
        .then(() => {
          hideModal();
        })
        .catch((e) => {
          setErrorMessage(e.message);
          helper.setErrors(e.response?.errors);
        });
    },
    [hideModal, onAction],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {title}
          </ModalHeader>
          <Formik initialValues={changeProspectApplicationStatusRequest} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <SelectInput
                    name="status"
                    options={[
                      ...Object.values(InquiryStatusEnum).map((status) => ({
                        value: status,
                        label: status.toUpperCase(),
                      })),
                    ]}
                  />
                  {errorMessage && <p className={'invalid-feedback-visible'}>{errorMessage}</p>}
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ChangeInquiryStatusModal;
