import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Container } from 'reactstrap';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { setGlobalLoading } from 'src/modules/app/actions';
import { Cell, Column } from 'react-table';
import ColumnMultiSelectFilter from 'src/components/Table/Filters/ColumnMultiSelectFilter';
import Api from 'src/api';
import { InquiryResponseDto } from 'src/types/api/landingPage/inquiry';
import Table from 'src/components/Table';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import CountryLabel from 'src/components/Labels/CountryLabel';
import ViewEntityButton from 'src/components/Table/Buttons/ViewEntityButton';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import { Permissions } from 'src/helpers/auth/permissions';
import { InquiryStatusEnum } from 'src/helpers/Enums/Inquiry/InquiryStatusEnum';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const Inquiries: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<InquiryResponseDto>>();

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<InquiryResponseDto>[] | any>(
    () => [
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<InquiryResponseDto>) => (
          <div>{t('common.datetime', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.company_name'),
        accessor: 'company_name',
        sortType: 'string',
        width: 200,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.company_name}</span>;
        },
      },
      {
        Header: t('table.country'),
        accessor: 'country',
        sortType: 'string',
        width: 100,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const row = cell.row.original;
          return row.country ? <CountryLabel code={row.country} /> : '-';
        },
      },
      {
        Header: t('table.required_amount'),
        accessor: 'required_amount',
        sortType: 'number',
        width: 150,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const row = cell.row.original;
          return <div>{t('common.money', { value: row.required_amount })}</div>;
        },
      },
      {
        Header: t('table.manager_name'),
        accessor: 'manager',
        sortType: 'string',
        width: 200,
        disableSortBy: true,
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          request: Api.user.fetchManagersOptions(),
        },
        Cell: (cell: Cell<InquiryResponseDto>) => {
          return <span>{cell.value}</span>;
        },
      },
      {
        Header: t('table.updated_at'),
        accessor: 'updated_at',
        sortType: 'string',
        width: 150,
        Cell: (cell: Cell<InquiryResponseDto>) => (
          <div>
            {cell.row.original.created_at !== cell.value
              ? t('common.datetime', {
                  date: cell.value,
                })
              : '-'}
          </div>
        ),
      },
      {
        Header: t('table.inquiry_status'),
        accessor: 'inquiry_status',
        sortType: 'string',
        width: 100,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          const statusTextColor =
            cell.value === InquiryStatusEnum.REJECTED
              ? 'text-danger'
              : cell.value === InquiryStatusEnum.CONVERTED
              ? 'text-success'
              : 'text-primary';
          return <span className={statusTextColor}>{cell.value.toUpperCase()}</span>;
        },
      },
      {
        Header: t('table.action'),
        width: 75,
        Cell: (cell: Cell<InquiryResponseDto>) => {
          return (
            <div className={'actions d-flex gap-2'}>
              <ViewEntityButton
                permissions={Permissions.BO__LANDING_PAGE__INQUIRIES__VIEW}
                route={generatePath(RouteList.LANDING_PAGE.INQUIRIES.VIEW, {
                  inquiryId: cell.row.original.id,
                })}
              />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.inquiries.fetchFilteredInquiries(request).then((response) => setData(response));
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-3'}>{t('menu.landing_page.inquiries')}</h4>
            <HiddenColumnsProvider title={'InquiryIndex'}>
              <Table
                title={'InquiryIndex'}
                onFetchData={fetchData}
                initialSortBy={INITIAL_SORT_ORDER}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Inquiries;
